import { Model } from "../libs/model/model";

export class CategorySingle extends Model {
  id: number = 0;
  name: string = "";
  description?: string = "";
  sequence: number = 0;
}

export class SubCategorySingle extends Model {
  id: number = 0;
  id_category: number = 0;
  name: string = "";
  description?: string = "";
}

export class ProductSingle extends Model {
  id: number = 0;
  code: string = "";
  id_category: number = 0;
  product_group: number = 0;
  product_name: string = "";
  description: string = "";
  valid_price: string = "";
  price: string = "";
  image: string = "";
  is_stock:boolean = false;
  stock:number | null = null;
  complement: AddonProductSingle[] = AddonProductSingle.hasMany(this);

  get getComplementList(): {
    type: string;
    data: AddonProductSingle[];
  }[] {
    let complements: {
      type: string;
      data: AddonProductSingle[];
    }[] = [];
    this.complement.forEach((item: AddonProductSingle) => {
      let index = complements.findIndex((x) => x.type === item.type);
      let a = new AddonProductSingle();
      a._loadJSON(item);
      if (index === -1) {
        let b = {
          type: item.type,
          data: [a],
        };
        complements.push(b);
      } else {
        complements[index].data.push(a);
      }
    });
    return complements;
  }
}

export class AddonProductSingle extends Model {
  id: number = 0;
  name: string = "";
  price: number = 0;
  type: string = "";
  is_default: number = 1;
}
