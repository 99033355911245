import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";

const getList = async (limit: string = "") => {

  console.log(JSON.stringify({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListCustomer`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      limit: limit,
    },
  }))
  const res = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListCustomer`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      limit: limit,
    },
  });
  if (Array.isArray(res)) {
    return res;
  }
  return [];
};

const getCompany = async () => {
  let res: any = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getHeaderPrint`,
    method: "POST",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      id_outlet: ConfigStore.id_outlet,
    },
  });
  if (typeof res === "object") {
    return res;
  }
  return {};
};

const CustomerAPI = {
  getList,
  getCompany,
};

export default CustomerAPI;
