import CheckBox from "@src/libs/ui/CheckBox";
import Modal from "@src/libs/ui/Modal";
import TextCurrencyInput from "@src/libs/ui/TextCurrencyInput";
import TextInput from "@src/libs/ui/TextInput";
import { moneyFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { Minus, Plus } from "heroicons-react";

import { runInAction, toJS } from "mobx";
import { observer ,useLocalObservable } from "mobx-react";
import React from "react";

const ModalItem = observer(({ currentOrder }: any) => {
  let tempOrderProduct = OrderStore.tempOrderProduct;
  
  if (!tempOrderProduct) return null;

  // alert("->"+parseFloat(tempOrderProduct.amount_discount))
  if(tempOrderProduct.isDiscount==false){
    if(parseFloat(tempOrderProduct.amount_discount)>0 ){
    
      runInAction(() => {
        tempOrderProduct.isDiscount=true
        tempOrderProduct.discAmtItem=tempOrderProduct.amount_discount
        tempOrderProduct.setDiscAmtItem()
      })
    }
  }
  
  
  const submit = () => {
    currentOrder.addOrderProduct(tempOrderProduct);
  };
  const deleteItem = () => {
    runInAction(() => {
      currentOrder.deleteOrderItem(tempOrderProduct.id);
    });
  };
  const dismiss = () => {
    OrderStore.initOrderProduct();
  };

  const meta = useLocalObservable(() => ({
    focusedDiscount: false,
  }));

  
  


  return (
    <Modal visible={tempOrderProduct.id !== 0} onDismiss={dismiss}
    
    
    >
      {(meta.focusedDiscount==false || ConfigStore.isWeb) &&
        <div className="bg-white px-4 pt-4">
          <div className="sm:flex sm:items-start">
            <div className="flex flex-col h-full">
              <div
                className={`h-40 w-40 flex-none overflow-hidden bg-cover bg-center rounded-lg`}
                style={{
                  backgroundImage: `url("${tempOrderProduct.product?.image}")`,
                }}
              />
              <div className="flex flex-row justify-between self-center mt-4">
                <div className="flex flex-row">
                  <button
                    className="w-10 h-10 bg-blue-500 rounded-md flex justify-center items-center hover:shadow-lg ripple"
                    disabled={tempOrderProduct.qty < 2}
                    onClick={() => {
                      tempOrderProduct.qty -= 1;
                      tempOrderProduct.set_amount_discount()
                      tempOrderProduct.set_discount()
                    }}
                  >
                    <Minus size={20} color="white" />
                  </button>
                  <TextInput
                    className="h-10 w-16 text-center font-bold text-lg my-0 mx-1"
                    value={String(tempOrderProduct.qty)}
                    onChange={(e) =>
                      runInAction(() => {
                        tempOrderProduct.qty = Number(
                          e.target.value.replace(/\D/g, "")
                        );

                        if(tempOrderProduct.is_stock && tempOrderProduct.qty >= (tempOrderProduct.product?.stock??9999)){
                          tempOrderProduct.qty = tempOrderProduct.product?.stock??9999
                        }
                        
                        tempOrderProduct.set_amount_discount()
                        tempOrderProduct.set_discount()
                      })
                    }
                  />
                  <button
                    className="w-10 h-10 bg-blue-500 rounded-md flex justify-center items-center  hover:shadow-lg ripple"
                    disabled={tempOrderProduct.is_stock && tempOrderProduct.qty >= (tempOrderProduct.product?.stock??9999)}
                    onClick={() => {
                      runInAction(() => {
                        tempOrderProduct.qty += 1;
                        tempOrderProduct.set_amount_discount()
                        tempOrderProduct.set_discount()
                      });
                    }}
                  >
                    <Plus size={20} color="white" />
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex flex-col">
              <h1
                className="text-xl font-bold leading-6 text-gray-800"
                id="modal-headline"
              >
                {tempOrderProduct.product?.product_name}
              </h1>
              
              {tempOrderProduct.product?.is_stock && (
              <h4 className="text-gray-700 text-md">
                {"Stock: "+moneyFormat(tempOrderProduct.product?.stock || 0, "")}
              </h4>)}

              <h4 className="text-gray-700 text-md">
                {moneyFormat(tempOrderProduct.product?.price || 0, "Rp. ")}
              </h4>
              <div className="flex-wrap mt-5 overflow-y-auto">
                {console.log(tempOrderProduct.product)}
                {tempOrderProduct.product?.getComplementList.map((x, key) => {
                  return <RenderComplement key={key} item={x} />;
                })}
              </div>
            </div>
          </div>
        </div>
      }  
      {/*/////////////////////////////////////////////////////////////////////////////// Discount */}
      <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto mt-6">
            <div className="flex flex-1">

            </div>
            <div 
                  style={{flexDirection: 'row', 
                          justifyContent: 'flex-end',
                          width:280
                        }}
            >
                    <div>    
                      {/* <CheckBox
                        label="Diskon per Item"
                        checked={tempOrderProduct.isDiscount}
                        // disabled={tempOrderProduct.promo.id!==0}
                        onChange={(e) => {
                          runInAction(() => {
                            tempOrderProduct.isDiscount = !tempOrderProduct.isDiscount;
      
                            if(!tempOrderProduct.isDiscount){
                              tempOrderProduct.discItem="0"
                              tempOrderProduct.discAmtItem="0"
                            }

                            tempOrderProduct.set_amount_discount()
                            tempOrderProduct.set_discount()
                          });
                        }}
                        
                      /> */}
                    </div>
                    {tempOrderProduct?.isDiscount && (
                          <table className="mb-3  w-64">
                        <tr>
                          <td className="mr-1">
                            <h2 className="text-xs mb-1 mt-2 text-gray-700 mr-1">Diskon Persen :</h2>
                          </td>
                          <td className="">
                            <h2 className="text-xs mb-1 mt-2 text-gray-700 ml-1">Diskon Harga :</h2>
                          </td>
                        </tr>
                        <tr>
                        <td className="mr-1">
                          <div>
                            <TextCurrencyInput
                                placeholder="Please enter a number"
                                // disabled={currentOrder.promo.id!==0}
                                value={parseFloat(tempOrderProduct?.discItem)>0?tempOrderProduct?.discItem:""}
                                onValueChange={(value:any, name:any) => {
                                  tempOrderProduct.discItem = (!!value?.toString()?value.toString():"0")
                                  tempOrderProduct.setDiscItem()
                                }}
                                type="percent"
                                max={100}
                                style={{
                                  width:75,
                                  marginRight: "0.25rem"
                                }}  
                                autoFocus={currentOrder.isDiscount}
                                onFocus={(event:any) => {meta.focusedDiscount=true}}
                                onBlur={(event:any) => {meta.focusedDiscount=false}}
                              />
                              </div>
                          </td>
                          <td className="ml-1">
                            <div className="bg-gray-100 rounded ml-1"
                            style={{
                              width:"100%"
                            }}>
                                <TextCurrencyInput
                                placeholder="Please enter a number"
                                // disabled={currentOrder.promo.id!==0}
                                value={parseFloat(tempOrderProduct?.discAmtItem)>0?tempOrderProduct?.discAmtItem:""}
                                onValueChange={(value:any, name:any) => {
                                  tempOrderProduct.discAmtItem = (!!value?.toString()?value.toString():"0")
                                  tempOrderProduct.setDiscAmtItem()
                                }}
                                type="amount"
                                style={{
                                  width:75,
                                  marginRight: "0.25rem"
                                }}  
                                onFocus={(event:any) => {meta.focusedDiscount=true}}
                                onBlur={(event:any) => {meta.focusedDiscount=false}}
                                // onFocus={(event:any) => {meta.focusedDiscountAmt=true}}
                                // onBlur={(event:any) => {meta.focusedDiscountAmt=false}}
                              />
                              </div>
                          </td>
                        </tr>
                        </table>
                        )}
            </div>
      
      
      </span>
      {/* END Discount */}
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            type="button"
            className="justify-center w-full h-12 rounded-md border border-transparent px-8 py-2 bg-green-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={submit}
            disabled={tempOrderProduct.qty === 0}
          >
            Simpan
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto align-bottom">
          <button
            type="button"
            className="justify-center w-full h-12 bottom-0 rounded-md border border-gray-300 px-8 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={dismiss}
          >
            Batal
          </button>
        </span>
        {tempOrderProduct.mode === "update" && (
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className="justify-center w-full rounded-md border border-transparent px-8 py-2 bg-red-100 text-xl leading-6 font-medium text-red-600 shadow-sm hover:bg-red-200 focus:outline-none focus:border-red-200 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={deleteItem}
            >
              Hapus
            </button>
          </span>
        )}
        <div className="flex-col flex flex-1 items-start">
          <table>
            {parseFloat(tempOrderProduct.discount)>0 &&
            <>
              <tr>
                <td>
                  <label>Subtotal item</label>
                </td>
                <td className="mx-3">
                  <label className="mx-3">{"  :  "}</label>
                </td>

                <td className="text-right">
                  <label className="text-lg font-bold text-right ">
                    {moneyFormat(tempOrderProduct.subtotal, "Rp. ")}
                  </label>
                </td>
              </tr>

              <tr>
                <td>
                  <label>Diskon</label>
                </td>
                <td className="mx-3">
                  <label className="mx-3">{"  :  "}</label>
                </td>
                <td className="text-right">
                <label className="text-lg font-bold text-right ">
                    {moneyFormat(tempOrderProduct.amount_discount, "Rp. ")}
                  </label>
                </td>
              </tr>

            </>
            
            }

            <tr>
              <td>
                <label>Total item</label>
              </td>
              <td className="mx-3">
                <label className="mx-3">{"  :  "}</label>
              </td>
              <td className="text-right">
              <label className="text-lg font-bold text-right flex-1">
                  {moneyFormat(tempOrderProduct.total, "Rp. ")}
                </label>
              </td>
            </tr>
          </table>  
        </div>
      </div>
    </Modal>
  );
});

const RenderComplement = observer((props: any) => {
  const { item } = props;
  let tempOrderProduct = OrderStore.tempOrderProduct;
  const addExtra = (x: any) => {
    runInAction(() => {
      tempOrderProduct.addExtraData(x);
    });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="mb-1">{item.type}</div>
      <div className="overflow-x-auto">
        <div className="flex flex-1">
          {item.data.map((x: any, key: any) => {
            let selected = false;
            let exist = tempOrderProduct.complement.find((y) => y.id === x.id);
            if (!!exist) {
              selected = true;
            }
            return (
              <button
                key={key}
                className={`rounded-full p-1 px-6 mr-2 bg-gray-200 text-md mb-2 ripple hover:shadow-md ${
                  !!selected ? "bg-blue-600 text-white" : ""
                }`}
                onClick={() => addExtra(x)}
              >
                {x.name}
                <h4
                  className={`text-xs ${
                    !!selected ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {!x.price || x.price === 0 ? "Free" : moneyFormat(x.price)}
                </h4>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default ModalItem;
