import { navigate } from "@reach/router";
import custom from "@src/assets/images/custom.png";
import gofood from "@src/assets/images/gofood.jpg";
import grabfood from "@src/assets/images/grabfood.jpg";
import logo from "@src/assets/images/ic_logo.png";
import kelava from "@src/assets/images/pos-kelava.jpg";
import CheckBox from "@src/libs/ui/CheckBox";
import Modal from "@src/libs/ui/Modal";
import TextAreaInput from "@src/libs/ui/TextAreaInput";
import TextInput from "@src/libs/ui/TextInput";
import ConfigStore from "@src/model/config";
import { CustomerSingle } from "@src/model/customer-single";
import OrderStore from "@src/model/order-repo";
import { OrderSingle } from "@src/model/order-single";
import ProductStore from "@src/model/product-repo";
import PromoStore from "@src/model/promo-repo";
import { Check } from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { FixedSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import defOrder from "@src/assets/images/default_order_method.png";

const ModelCustomerInfo: React.FC = observer(() => {
  const tempOrder = OrderStore.tempOrder;
  const meta = useLocalObservable(() => ({
    visibleSugest: false,
    focus: "",
    focusedName: false
  }));
  const cancel = () => {
    if (OrderStore.listDraft.length > 0) {
      OrderStore.tempOrder._loadJSON(new OrderSingle()._json);
    } else {
      navigate("order-list");
    }
  };
  const submit = async () => {
    await OrderStore.saveDraftOrder();
    ProductStore.reload()
    PromoStore.reload();
    OrderStore.tempOrder._loadJSON(new OrderSingle()._json);
    OrderStore.currentOrder.deleteOrderItems();
  };

  const scrollToRef = (ref:any) => window.scrollTo(0, ref.current.offsetTop) 
  const myRef = useRef(null)
   const executeScroll = () => scrollToRef(myRef)

   const OrderMethod=ConfigStore.orderMethod;


   useEffect(() => {
    tempOrder.is_member = true
   },[]);
  // const OrderMethod: {
  //   image: string;
  //   value: "POS" | "GRABFOOD" | "GOFOOD" | "CUSTOM";
  // }[] = [
  //   {
  //     image: kelava,
  //     value: "POS",
  //   },
  //   {
  //     image: gofood,
  //     value: "GOFOOD",
  //   },
  //   {
  //     image: grabfood,
  //     value: "GRABFOOD",
  //   },
  //   {
  //     image: custom,
  //     value: "CUSTOM",
  //   },
  // ];
  return (
    <Modal visible={!!OrderStore.tempOrder.id} onDismiss={cancel}>
      <div className="bg-white p-4 w-100">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-col">
          <img
          className="h-14 w-24 rounded-lg align-middle mx-auto"
          src={logo}
          alt="almuslim"
        />
            <h1
              className="text-xl font-bold leading-6 text-gray-800 mv-3  align-middle mx-auto"
              id="modal-headline"
            >
              Buat Penjualan
            </h1>
            <div className="mb-2">
            {false && (//(!meta.focusedName || ConfigStore.isWeb) &&(
              <>
              <h2 className="text-xs mb-2 text-gray-700">Order dari :</h2>
              <div className="flex flex-row">
              <AutoSizer
                style={{
                  height: "150px",
                  width: "100%"
                  
                }}
              >
                {({ height, width }: any) => {
                 let baseW = width - 10,
                 baseCol = 100,
                 col = 5,//Math.floor(baseW / baseCol),
                 modW = baseW % baseCol,
                 colw = modW / col + baseCol,
                 baseRow = 150,
                 row = Math.ceil(OrderMethod.length / col);

                  return (
                    <Grid
                      height={height}
                      width={width}
                      columnCount={5}
                      columnWidth={145}
                      rowCount={row}
                      rowHeight={baseRow}
                      itemData={OrderMethod}
                    >
                      {(props: any) => {
                        const index = col * props.rowIndex + props.columnIndex;
                        if((index+1)>OrderMethod.length){
                          return null
                        }
                        const active = OrderMethod[index].nama === tempOrder?.method;
                        return (
                          <div key={index} className="mb-0 mr-5 flex flex-1 flex-col GridItem p-2"
                          style={props.style}
                          >
                            <button
                              className={`opacity-75 hover:opacity-100 border-gray-400 border-2 rounded-lg overflow-hidden relative ${
                                !!active && "border-blue-400 opacity-100"
                              }`}
                              onClick={() => {
                                if (!!tempOrder) {
                                  runInAction(() => {
                                    tempOrder.method = OrderMethod[index].nama;
                                    tempOrder.customer_code = "";
                                    tempOrder.id_customer = 0;
                                    tempOrder.name = OrderMethod[index].default_customer_name!=="null" && OrderMethod[index].default_customer_name!=="" ?OrderMethod[index].nama:""
                                  });
                                }
                              }}
                            >
                              {!!active && (
                                <>
                                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-300 opacity-25" />
                                  <Check
                                    size={35}
                                    className="absolute top-0 right-0 m-1 text-blue-600 bg-white rounded-full p-1 border border-blue-300"
                                  />
                                </>
                              )}
                              {
                                OrderMethod[index].img!==""?
                                <div
                                className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                                style={{ backgroundImage: `url(${OrderMethod[index].img})` }}
                              />
                              :
                              OrderMethod[index].img_url!=="null"&&OrderMethod[index].img_url!==""?
                              <div
                                className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                                style={{ backgroundImage: `url(${
                                  ConfigStore.server_url +
                                  "/" +
                                  OrderMethod[index].img_url.replace(/ /g, "%20")
                                })`,
                                //backgroundColor:"#FFFF00",
                                backgroundSize:"cover"
                              
                              }}
                              />:
                              <div
                                className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                                style={{ backgroundImage: `url(${defOrder})`,
                                //backgroundColor:"#FF0000",
                                backgroundSize:"cover"
                                
                              
                              }}
                              />

                              }
                              
                            </button>
                            <div className="text-sm text-center">{OrderMethod[index].nama}</div>
                          </div>
                        );
                      }}
                    </Grid>

                  )}}

              </AutoSizer>
              

                {/* {ConfigStore.orderMethod.map((item, idx) => {
                  const active = item.nama === tempOrder?.method;
                  return (
                    <div key={idx} className="mb-2 mr-5 flex flex-1 flex-col">
                      <button
                        className={`opacity-75 hover:opacity-100 border-gray-400 border-2 rounded-lg overflow-hidden relative ${
                          !!active && "border-blue-400 opacity-100"
                        }`}
                        onClick={() => {
                          if (!!tempOrder) {
                            runInAction(() => {
                              tempOrder.method = item.nama;
                              tempOrder.customer_code = "";
                              tempOrder.id_customer = 0;
                            });
                          }
                        }}
                      >
                        {!!active && (
                          <>
                            <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-300 opacity-25" />
                            <Check
                              size={35}
                              className="absolute top-0 right-0 m-1 text-blue-600 bg-white rounded-full p-1 border border-blue-300"
                            />
                          </>
                        )}
                        {
                          item.img!==""?
                          <div
                          className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                          style={{ backgroundImage: `url(${item.img})` }}
                        />
                        :
                        <div
                          className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                          style={{ backgroundImage: `url(${
                            ConfigStore.server_url +
                            "/" +
                            item.img_url.replace(/ /g, "%20")
                          })` }}
                        />

                        }
                        
                      </button>
                      <div className="text-sm text-center">{item.nama}</div>
                    </div>
                  );
                })} */}
                
              </div>
              </>
            )}
              
            </div>
            <div
              className={`grid grid-cols-12 ${
                tempOrder.is_new_customer && "gap-4"
              }`}
            >
              <div
                className={`flex flex-col ${
                  tempOrder.is_new_customer ? "col-span-6 gap-4" : "col-span-12"
                }`}
              >
                {tempOrder?.method === "POS" || tempOrder?.method === "CUSTOM" ? (
                  <>
                    {/* <div className={"mb-2"}>
                      <CheckBox
                        label="Pelanggan Tetap?"
                        checked={tempOrder?.is_member}
                        onChange={(e) => {
                          if (!!tempOrder) {
                            if (!tempOrder.is_member) {
                              
                              runInAction(() => {
                                tempOrder.is_member = !tempOrder.is_member;
                              });
                            } else {
                              
                              runInAction(() => {
                                tempOrder.customer_code = "";
                                tempOrder.name = "Guest";
                                tempOrder.phone = "";
                                tempOrder.id_customer = 0;
                                tempOrder.is_new_customer = false;
                                tempOrder.is_member = !tempOrder.is_member;
                              });
                            }
                          }
                        }}
                      />
                    </div> */}

                    <div className={"mb-2 flex-1 flex-col relative inline-block"}>
                  <h2 className="text-xs mb-1 text-gray-700">NIS :</h2>
                  <TextInput
                    className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100 w-full"
                    value={tempOrder?.phone}
                    onChange={(e) => {
                      if (!!tempOrder) {
                        runInAction(() => {
                          tempOrder.phone = e.target.value;
                          meta.visibleSugest =
                            OrderStore.customerByMember.length > 0;
                        });
                      }
                      if (!!tempOrder.id_customer) {
                        runInAction(() => {
                          tempOrder.customer_code = "";
                          tempOrder.name = "";
                          tempOrder.id_customer = 0;
                          tempOrder.is_new_customer = false;
                        });
                      }
                    }}
                    onFocus={() => {
                      runInAction(() => {
                        meta.visibleSugest =
                          OrderStore.customerByMember.length > 0;
                        meta.focus = "phone";
                      });
                    }}
                    onBlur={() => {
                      if (!!meta.visibleSugest) {
                        setTimeout(() => {
                          runInAction(() => {
                            meta.visibleSugest = false;
                            meta.focus = "";
                          });
                        }, 500);
                      }
                    }}
                  />
                  {!!tempOrder.is_member && meta.focus === "phone" && (
                    <CustomerSugestion meta={meta} />
                  )}
                </div>
                  </>
                ) : (
                  <>
                  {/* // <div className={"mb-2 flex flex-col"}>
                  //   <h2 className="text-xs mb-1 text-gray-700">
                  //     Kode Referensi :
                  //   </h2>
                  //   <TextInput
                  //     className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                  //     value={tempOrder?.purchase_order_number}
                  //     onChange={(e) => {
                  //       if (!!tempOrder) {
                  //         runInAction(() => {
                  //           tempOrder.purchase_order_number = e.target.value;
                  //         });
                  //       }
                  //     }}
                  //   />
                  // </div> */}
                  </>
                )}
                {/* <div className={"mb-2 flex-1 flex-col relative inline-block"}>
                  <h2 className="text-xs mb-1 text-gray-700">Telp :</h2>
                  <TextInput
                    className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100 w-full"
                    value={tempOrder?.phone}
                    onChange={(e) => {
                      if (!!tempOrder) {
                        runInAction(() => {
                          tempOrder.phone = e.target.value;
                          meta.visibleSugest =
                            OrderStore.customerByMember.length > 0;
                        });
                      }
                      if (!!tempOrder.id_customer) {
                        runInAction(() => {
                          tempOrder.customer_code = "";
                          tempOrder.name = "";
                          tempOrder.id_customer = 0;
                          tempOrder.is_new_customer = false;
                        });
                      }
                    }}
                    onFocus={() => {
                      runInAction(() => {
                        meta.visibleSugest =
                          OrderStore.customerByMember.length > 0;
                        meta.focus = "phone";
                      });
                    }}
                    onBlur={() => {
                      if (!!meta.visibleSugest) {
                        setTimeout(() => {
                          runInAction(() => {
                            meta.visibleSugest = false;
                            meta.focus = "";
                          });
                        }, 500);
                      }
                    }}
                  />
                  {!!tempOrder.is_member && meta.focus === "phone" && (
                    <CustomerSugestion meta={meta} />
                  )}
                </div> */}
                <div className={"mb-2 flex-1 flex-col relative inline-block"}>
                  <h2 className="text-xs mb-1 text-gray-700"
                  
                  ref={myRef}
                  >Nama :</h2>
                  <TextInput
                    className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100 w-full"
                    value={tempOrder.name}
                    onChange={(e) => {
                      if (!!tempOrder) {
                        runInAction(() => {
                          tempOrder.name = e.target.value;
                          meta.visibleSugest =
                            OrderStore.customerByMember.length > 0;
                        });
                      }
                      if (!!tempOrder.id_customer) {
                        runInAction(() => {
                          tempOrder.customer_code = "";
                          tempOrder.phone = "";
                          tempOrder.id_customer = 0;
                          tempOrder.is_new_customer = false;
                        });
                      }
                    }}
                    onFocus={() => {
                      runInAction(() => {
                        meta.visibleSugest =
                          OrderStore.customerByMember.length > 0;
                        meta.focus = "name";
                      });
                      meta.focusedName=true;
                      executeScroll();
                      
                    }}
                    onBlur={() => {
                      if (!!meta.visibleSugest) {
                        setTimeout(() => {
                          runInAction(() => {
                            meta.visibleSugest = false;
                            meta.focus = "";
                          });
                        }, 500);
                      }
                      meta.focusedName=false;
                    }}
                  />
                  
                  {!!tempOrder.is_member && meta.focus === "name" && (
                    <CustomerSugestion meta={meta} />
                  )}

                  {!ConfigStore.isWeb && meta.focusedName &&(
                  <div
                    style={{
                      paddingBottom:124,
                    }}
                    />
                )}
                </div>
                { false && !!tempOrder.is_member && (
                  <div className={"mb-2"}>
                    <CheckBox
                      label="Daftar Pelanggan Tetap?"
                      checked={tempOrder?.is_new_customer}
                      onChange={(e) => {
                        if (!!tempOrder) {
                          runInAction(() => {
                            tempOrder.is_new_customer = !tempOrder.is_new_customer;
                          });
                        }
                      }}
                      disabled={
                        !!tempOrder.id_customer && !!tempOrder.customer_code
                      }
                    />
                  </div>
                )}
                {tempOrder?.method === "CUSTOM" && (
                  <>
                    <div className={"mb-2 flex flex-col"}>
                      <h2 className="text-xs mb-1 text-gray-700">Nomor PO :</h2>
                      <TextInput
                        className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                        value={tempOrder?.purchase_order_number}
                        onChange={(e) => {
                          if (!!tempOrder) {
                            runInAction(() => {
                              tempOrder.purchase_order_number = e.target.value;
                            });
                          }
                        }}
                      />
                    </div>
                    <div className={"mb-2 flex flex-col"}>
                      <h2 className="text-xs mb-1 text-gray-700">
                        Tanggal Sales Order :
                      </h2>
                      <TextInput
                        className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                        value={tempOrder.sales_order_date}
                        type="date"
                        onChange={(e) => {
                          if (!!tempOrder) {
                            runInAction(() => {
                              tempOrder.sales_order_date = e.target.value;
                            });
                          }
                        }}
                      />
                    </div>
                    {/* <div className={"mb-2 flex flex-col"}>
                  <h2 className="text-xs mb-1 text-gray-700">PPN :</h2>
                  <TextInput
                    className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                    value={tempOrder?.ppn}
                    onChange={(e) => {
                      if (!!tempOrder) {
                        runInAction(() => {
                          tempOrder.ppn = Number(e.target.value);
                        });
                      }
                    }}
                  />
                </div> */}
                  </>
                )}

                {false && !!tempOrder.is_member && !tempOrder.id_customer && (
                  <p className="text-red-500 text-xs">
                    Pelanggan tidak terdaftar. Silahkan melakukan Pendaftaran
                    dahulu atau menggunakan Pelanggan Tidak Tetap
                  </p>
                )}
              </div>
              {tempOrder.is_new_customer && (
                <div className="col-span-6 flex flex-col">
                  <div className={"mb-2 flex flex-col"}>
                    <h2 className="text-xs mb-1 text-gray-700">Email :</h2>
                    <TextInput
                      className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                      value={tempOrder?.email}
                      onChange={(e) => {
                        if (!!tempOrder) {
                          runInAction(() => {
                            tempOrder.email = e.target.value;
                          });
                        }
                      }}
                    />
                  </div>
                  <div className={"mb-2 flex flex-col"}>
                    <h2 className="text-xs mb-1 text-gray-700">Alamat :</h2>
                    <TextAreaInput
                      className="mx-0 my-0 px-2 py-1 text-sm border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                      value={tempOrder.address}
                      onChange={(e) => {
                        runInAction(() => (tempOrder.address = e.target.value));
                      }}
                      rows={3}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            type="button"
            className="inline-flex justify-center w-50 rounded-md border border-transparent px-4 py-2 bg-blue-600 text-lg leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={submit}
            disabled={ !tempOrder.name || !tempOrder.phone}
          >
            Create
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            className="inline-flex justify-center w-50 rounded-md border border-gray-300 px-4 py-2 bg-white text-lg leading-6 font-medium text-blue-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={cancel}
          >
            Cancel
          </button>
        </span>
      </div>
    </Modal>
  );
});

const CustomerSugestion = observer((props: any) => {
  const { meta } = props;
  const tempOrder = OrderStore.tempOrder;

  return (
    <div
      className={`shadow-md rounded py-1 bg-white w-full absolute z-30 overflow-auto h-32 ${
        !!meta.visibleSugest ? "block" : "hidden"
      }`}
    >
      {OrderStore.customerByMember.map((item: CustomerSingle, key) => (
        
        <button
          key={key}
          disabled={false}
          className="text-sm hover:bg-gray-200 px-2 py-1 button w-full text-left border-b border-gray-200"
          onClick={() => {
            runInAction(() => {
              OrderStore.tempOrder.customer_code = item.code;
              OrderStore.tempOrder.name = item.name;
              OrderStore.tempOrder.phone = item.phone1;
              OrderStore.tempOrder.id_customer = item.id;
              OrderStore.tempOrder.is_new_customer = false;
              meta.visibleSugest = false;
            });
          }
        }
        onFocus={() =>{
          runInAction(() => {
            OrderStore.tempOrder.customer_code = item.code;
            OrderStore.tempOrder.name = item.name;
            OrderStore.tempOrder.phone = item.phone1;
            OrderStore.tempOrder.id_customer = item.id;
            OrderStore.tempOrder.is_new_customer = false;
            meta.visibleSugest = false;
          });
        }}
        >
          {item.name} - {item.phone1}
        </button>
      ))}
    </div>
  );
});

export default ModelCustomerInfo;
